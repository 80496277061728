import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router-dom";
import "./App.css";
import Timelinepage from "./timeline/Jira/Timelinepage_Jira";
import Login from "./Login";
import TimelinePageAdoClass from "./timeline/AzureDevOps/TimelinePage_AdoClass";
import TimelinePageAdo from "./timeline/AzureDevOps/TimelinePageAdo";
import OpsTriagePage from "./triage/OpsTriagePage";
import TriagePage from "./triage/TriagePage";
import { RequestProvider } from "./api/Azure/requestContainer";
import * as LocalConst from "./common/localVariableDoNotCheckIn";
import store from 'store2';


class App extends Component {
  constructor(props) {
    super(props);
    // ToDo Store Data in Local storage in browser 
    const _authJira = JSON.parse(sessionStorage.getItem("authJira"));
    const _authAdo_un = store.get("Auth_un");
    const _authAdo_pw = store.get("Auth_pw");
    const _authAdo = {un: _authAdo_un ? _authAdo_un : "", pw: _authAdo_pw ? _authAdo_pw : ""};

    this.state = 
    { 
        authJira: { un: _authJira ? _authJira.un : "", pw: _authJira ? _authJira.pw : "" },
        authAdo:  { un: _authAdo ? _authAdo.un : LocalConst.authAdoUsername, pw: _authAdo ? _authAdo.pw :  LocalConst.authAdoPassword }
    };
  }

  updateAuthAdo = (auth) => {
    const _authAdo = {un: auth.un, pw: auth.pw};
    store.set("Auth_un", auth.un );
    store.set("Auth_pw", auth.pw);
    this.setState({ authAdo: _authAdo});

  }
  updateAuthJira = (auth) => {
    const _authJira = { un: auth.un, pw: auth.pw };
    this.setState({ authJira: _authJira });
    // ToDo Store Data in Local storage in browser 
    sessionStorage.setItem("authJira", JSON.stringify(_authJira));
  };

  render() {
    return (
      <RequestProvider>  
        <Router>
          <Route
            path="/"
            exact
            render={(props) => <TimelinePageAdo auth={this.state.authAdo} updateAuth={this.updateAuthAdo} {...props} />}
            />
          <Route
            path="/jira"
            exact
            render={(props) => <Timelinepage authJira={this.state.authJira} {...props} />}
            />
          <Route path="/adoclass" render={(props) => <TimelinePageAdoClass auth={this.state.authAdo} {...props} />} />
          <Route path="/ado" render={(props) => <TimelinePageAdo auth={this.state.authAdo} updateAuth={this.updateAuthAdo} {...props} />} />
          <Route path="/opstriage" render={(props) => <OpsTriagePage auth={this.state.authAdo} updateAuth={this.updateAuthAdo} {...props} />} />
          <Route path="/triage" render={(props) => <TriagePage auth={this.state.authAdo} updateAuth={this.updateAuthAdo} {...props} />} />
          
          <Route
            path="/login"
            render={(props) => <Login updateAuth={this.updateAuthJira} {...props} />}
            />
        </Router>
      </RequestProvider>
    );
  }
}

export default App;
