import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap"
import * as AzureService from "../../api/ticketsApiAzure";
import * as tConst from "../../common/Constants";
import * as date from "../../utils/date";

const ChartMenuToolbar = ({selectedTicket, onClose, auth }) => {

    const dis = (["Epic", "Feature", "Initiative"].indexOf(selectedTicket.fields[tConst.AF_TYPE]) > -1);
    return(
        <>
            <Button 
            variant="info" 
            size="sm"
            disabled={!dis} 
            onClick={()=>{
                tConst.AZURE_SPECS.map((spec) => {
                    return AddPlanningTasks(auth, selectedTicket, spec);
                })
                onClose();
                    
            }}>Add Planning tasks</Button>

            <Button
                variant="info" 
                size="sm"
                onClick={()=>{
                    AzureService.RemoveFromTimeline(auth, selectedTicket.id)
                    onClose();

                }}
            >Remove from Timeline</Button>
        </>
    )

}

const AddPlanningTasks = (auth, selectedTicket, spec) => {
    const initiative = selectedTicket.fields[tConst.AF_INITIATIV];
    const _project = selectedTicket.fields[tConst.AF_PROJECT];
    const _id = selectedTicket.id;
    const _title = spec + ": " +selectedTicket.fields[tConst.AF_TITLE];
    const PlanningPriority = selectedTicket.fields[tConst.AF_PLANNING_PRIORITY];
    const _PlanningPriority = 0.1 + Number(PlanningPriority)
    const _end = new Date(selectedTicket?.fields[tConst.AF_END_ITERATION]);
    const _start = new Date(selectedTicket?.fields[tConst.AF_START_ITERATION]);
    //Todo - implement check when reducing this to the same day or less
    const _dateDiff = date.date_diff_indays(_start, _end);

    const _FuncSpec =  tConst.AZURE_SPECS[0];


    if (spec === _FuncSpec) {
        _end.setDate(_end.getDate() - (14 + _dateDiff));
        _start.setDate(_start.getDate() - 28 )
    
    } else {
        _end.setDate(_end.getDate() - (_dateDiff));
        _start.setDate(_start.getDate() - 14 )
    } 

    const data = JSON.stringify([
        {
            "op": "add",
            "path": "/fields/System.Title",
            "from": null,
            "value": _title},
           {
            "op": "add",
            "path": "/relations/-",
            "value": {
              "rel": tConst.AF_REL_PARENT,

              "url": tConst.AZURE_DEVOPS_BASE_URI+_project+"/_apis/wit/workItems/"+_id
            }
        },
        {
            "op": "add",
            "path": "/fields/"+tConst.AF_INITIATIV,
            "value": initiative
        },
        {
            "op": "add",
            "path": "/fields/"+tConst.AF_PLANNING_PRIORITY,
            "value": _PlanningPriority
        },
        {
            "op": "add",
            "path": "/fields/"+tConst.AF_START_ITERATION,
            "value": _start
          },
          {
            "op": "add",
            "path": "/fields/"+tConst.AF_END_ITERATION,
            "value": _end
          },
          {
            "op": "add",
            "path": "/fields/"+tConst.AF_PLANNING_STATUS,
            "value": "9. N/A"
          }      


    ])  
    AzureService.createTicket(auth,_project, "product backlog item", data)

}

export default ChartMenuToolbar