import React, { Component } from "react";
import * as tConst from "../../common/Constants";
import ToolBarAdo from "./ToolBar_ADO";
import * as AzureService from "../../api/ticketsApiAzure";


class TimelinePageAdoClass extends Component {

    state = {
        tickets: [],
        isLoading: false,
        projects: null,
        //******* */
        selectedTicket: null,
        selectedTicketKey: "",
        firstStart: null,
        lastEnd: null,
        groupBy: tConst.GROUP_BY_INITIATIVE,
        view: tConst.VIEW_ACTIVE_AND_CURRENT,
        auth: null,
        selectedFilterId: -1,
        jiraFilters: tConst.JIRA_DEFAULT_FILTERS,
        epicsFilterList: tConst.DEFAULT_EPICS_FILTER,
        selectedEpicsFilter: tConst.DEFAULT_EPICS_FILTER[0].value,
        toolbarIsLoaded: false,
        initiativeFilterList: tConst.DEFAULT_INITIATIVE_FILTER,
        selectedInitiativeFilter: tConst.DEFAULT_INITIATIVE_FILTER[0].value,
        assigneeFilterList: tConst.DEFAULT_ASSIGNEE_FILTER,
        selectedAssigneeFilter: tConst.DEFAULT_ASSIGNEE_FILTER[0].value,
        sprintFilterList: tConst.DEFAULT_SPRINT_FILTER,
        selectedSprintFilter: tConst.DEFAULT_SPRINT_FILTER[0].value,
        openOnClickStatus: true,
        timelineIsLoaded: false,
        dataArray: null,
        minDate: null,
        maxDate: null,
        showChartMenu: false,
      };
        
    componentDidMount() {
        const { auth } = this.props;
        if (auth !== null && auth.un !== "" && auth.pw !== "") {
            this.getData(auth);
        }
    }
    
    render() {
        const { auth } = this.props;
        console.log("Auth:"+auth);
        if (auth !== null && auth.un !== "" && auth.pw !== "") {
            if (this.state.isLoading){
            
                return (
                <>
                    <ToolBarAdo/>
                </>
                );
            } 
            else {
                return (
                    <div>Loading...</div>
                )
            }
        }
        else{
            // Do something
            console.log("Auth:"+JSON.stringify(auth, null, 2));
            return (
                <div>Auth Missing: <br/> {JSON.stringify(auth, null, 2)}</div>
            )
        }
    } // render

    getData = (auth) => {
        // get Selected filter to find the correct url to fetch tickets
        this.getProject(auth);
        this.setState({isLoading: true});

    //    this.getItemsFromAdo(auth);
    
        /* TODO - port this over
        // Fetct Favorite filters from Jira
        AzureService.getFavoriteFilters(auth).then((filters) => {
          console.log("TimelinePage - getData.filters", filters);
          const _jirafilters = [...tConst.JIRA_DEFAULT_FILTERS];
          filters.map((filter) =>
            _jirafilters.push({
              value: filter.id,
              label: filter.name,
              searchUrl: filter.searchUrl,
              viewUrl: filter.viewUrl,
            })
          );
          this.setState({ jiraFilters: _jirafilters });
          this.setState({ toolbarIsLoaded: true });
          console.debug("TimelinePage - getData - this.state.jiraFilters", this.state.jiraFilters);
        });
*/
    };

    async getProject(auth){
        console.log("***Projects Before ****************************************");
        await AzureService.getProjects(auth).then((projects) => {
            this.setState({isLoading: false});
            this.setState({projects});
            this.setState({isLoading: true});
        });
        console.log("***Projects After****************************************");

    }

    /*
      getItemsFromAdo(auth, filterId = null) {
        const _filterId = filterId ? filterId : this.state.selectedFilterId;
        console.log("get_ItemsFromAdo", _filterId);
        const _jFilter = this.state.jiraFilters.find((jFilter) => {
          return jFilter.value === _filterId;
        });
    
        console.debug("TimelinePage - get_ItemsFromAdo.jFilters", _jFilter);
        const url = _jFilter
          ? _jFilter.searchUrl + "&maxResults=500" + "&fields=" + tConst.fields
          : tConst.JIRA_PLANNED_QUERY + "&maxResults=500" + "&fields=" + tConst.fields;
    
        // get tickets for selected filter from Jira
        AzureService.getItems(auth, url).then((items) => {
          console.log("tickets", items);
          const epics = [tConst.DEFAULT_EPICS_FILTER[0].value];
          const initiative = [tConst.DEFAULT_INITIATIVE_FILTER[0].value];
          const assignee = [tConst.DEFAULT_ASSIGNEE_FILTER[0].value];
          const sprint = [tConst.DEFAULT_SPRINT_FILTER[0].value]
          // Loop through the ticket list to produce the correct filters (based on ticket list)
          const tickets = items.map((ticket) => {
    
            const epicKey = ticket.epicLink;
            if (ticket.type === "Epic") {
              epics.push(ticket.key);
            } else if (epicKey) {
              epics.push(epicKey);
            }
    
            if (ticket.initiative !== "") {
              initiative.push(ticket.initiative);
            }
    
            if (ticket.assignee !== "") {
              assignee.push(ticket.assignee);
            }
    
            if (ticket.sprint.name !== "") {
              sprint.push(ticket.sprint.name);
            }
    
    
    
            return ticket;
          });
          this.prepareDataForChart(tickets);
          let uniqueEpics = [...new Set(epics)];
          const epics2 = uniqueEpics.map((epickey) => {
            return { label: epickey, value: epickey };
          });
    
    
          let uniqueInitiative = [...new Set(initiative)];
          const _initiative = uniqueInitiative.map((init) => {
            return { label: init, value: init };
          });
    
          let uniqueAssignee = [...new Set(assignee)];
          const _assignee = uniqueAssignee.map((init) => {
            return { label: init, value: init };
          });
          const __assignee = _assignee.sort((a, b) => a.label > b.label ? 1 : -1);
    
          let uniqueSprint = [...new Set(sprint)];
          const _sprint = uniqueSprint.map((init) => {
            return { label: init, value: init };
          });
          const __sprint = _sprint.sort((a, b) => a.label > b.label ? 1 : -1);
    
          //TODO: Figure out why sorting of <..> is not consisten
          // console.log("__assignee", __assignee);
          // console.log("__sprint", __sprint);
    
          this.setState({ tickets: tickets });
          this.setState({ epicsFilterList: epics2 });
          this.setState({ initiativeFilterList: _initiative });
          this.setState({ assigneeFilterList: __assignee });
          this.setState({ sprintFilterList: __sprint });
          this.setState({ timelineIsLoaded: true });
        });
    }
      */
    

    
}


export default TimelinePageAdoClass;