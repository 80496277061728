import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as tConst from "../../common/Constants";

class ChartSprintHeaderMenuAdo extends Component {
  state = {
    selectedTicket: null,
    ticketId : ""
  }

  render() {
    const selectedSprint = this.props.selectedSprint;

    if (this.props.showSprintHeaderChartMenu && selectedSprint) {
      return (
        <>
          <Modal show={this.props.showSprintHeaderChartMenu}>
            <Modal.Header>
              <Modal.Title>
                <h3>Add ticket to sprint {this.props.selectedSprint.SprintName}</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <div>Ticket ID</div>
              <input placeholder="Ticket id" value={this.state.ticketId} onChange={(event) => {this.setState({ticketId: event.target.value})}}></input>
              <button 
                onClick={(event) => {
                  this.props.Search(this.state.ticketId).then(
                    (response) => 
                      {
                        this.setState({selectedTicket: response})
                        console.log("Search Reponse:"+ JSON.stringify(response, null, 4))
                      }
                    )
                }}
              >
                Search
              </button> 
              
              {this.state.selectedTicket ? 
                <><hr/>
                <div>
                  <button 
                    onClick={
                      (event) => {
                        this.props.onAddTicketToSprint(this.state.ticketId, selectedSprint.StartDate, selectedSprint.EndDate).then(
                          (response) =>{
                            console.log("onAddTicketToSprint" + JSON.stringify(response, null, 4));
                            this.props.onSetRefresh();
                            this.props.handleClose();
                          }
                        )
                      }
                    }
                  >Add {this.state.selectedTicket.id}</button>
                  &nbsp; {this.state.selectedTicket.fields[tConst.AF_TITLE]}
                </div>
                </>  
                : <></>}  
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.props.handleClose} variant="primary">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
    else{
      return <></>
    }  
  
  }
}

export default ChartSprintHeaderMenuAdo;
