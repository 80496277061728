import React from 'react';

const OpsTriagePage = ({auth, updateAuth}) => {



        return (
            <div>
                <h1>Ops Triage Page</h1>
                <p>Welcome to the Ops Triage Page!</p>
                
            </div>
        );
};

export default OpsTriagePage;