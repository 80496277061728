import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as tConst from "../../common/Constants";
import ChartMenuMoveExtendButtons from "./ChartMenuMoveExtendButtons"
import ChildrenMenuToolbar from "./ChildrenMenuToolbar"
import ChartMenuToolbar from "./ChartMenuToolbar"

class ChartMenuAdo extends Component {
  render() {
    const selectedTicket = this.props.selectedTicket;

    if (this.props.showChartMenu && selectedTicket) {
      console.log("ChartMenuAdo..props.selectedTicket" + JSON.stringify(selectedTicket, null, 4));
      const _fromDate = new Date (selectedTicket.fields[tConst.AF_START_ITERATION]);
      const _toDate = new Date(selectedTicket.fields[tConst.AF_END_ITERATION]);
      const _assignedTo =  selectedTicket.fields[tConst.AF_ASSIGNED_TO];
      const _initiaitve = selectedTicket.fields[tConst.AF_INITIATIV];
      const _jiraLink = selectedTicket ? "https://jira.sensio.no/browse/"+selectedTicket.fields[tConst.AF_JIRA_LINK] : "";
      return (
        <>
          <Modal show={this.props.showChartMenu}>
            <Modal.Header>
              <Modal.Title>
                <a href={tConst.AZURE_DEVOPS_BASE_TICKET_URI + this.props.menuTicketID} target="_blank" rel="noopener noreferrer">
                  [{this.props.menuTicketID}] 
                </a>
                &nbsp;
                  {selectedTicket?.fields[tConst.AF_TITLE]}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                Period: &nbsp;
                {_fromDate ? tConst.formatDate(_fromDate) : ""} -{" "}
                {_toDate ? tConst.formatDate(_toDate) : ""} <br />
                Assignee: {_assignedTo ? _assignedTo.displayName : tConst.ADO_UNASSIGNED} <br />
                Status: {selectedTicket.fields[tConst.AF_STATE]} <br />
                Initiative: <a href={tConst.AZURE_DEVOPS_BASE_URI +  "_search?type=workitem&text=initiative%3A%22"+ _initiaitve+"%22"} target="_blank" rel="noopener noreferrer">{_initiaitve}</a><br/>
                Planning Priority: {selectedTicket.fields[tConst.AF_PLANNING_PRIORITY]} <br/>
                Jira Ref: <a href={_jiraLink}>{selectedTicket.fields[tConst.AF_JIRA_LINK]}</a> <br/>
                <ChartMenuToolbar
                  auth={this.props.auth}
                  selectedTicket={selectedTicket} 
                  onClose={()=> {
                    this.props.onSetRefresh();
                    this.props.handleClose();
                  }}
                />
                </div>

    {/*} 

                <br />
                Demo:{" "}
                <Select
                  options={tConst.DEMO_SELECT_LIST}
                  name="view"
                  defaultValue={tConst.DEMO_SELECT_LIST.find((item) => item.value === demo)}
                  onChange={(event) => this.props.updateDemo(key, event.value)}
                  style={{ width: "40px" }}
                />
    {*/} 

                <hr/>
                <ChartMenuMoveExtendButtons 
                  selectedTicket={selectedTicket} 
                  onExtendIteration = {this.props.onExtendIteration}
                  enableHalfIterations = {this.props.enableHalfIterations}
                  onMoveIteration = {this.props.onMoveIteration}
                />
                <hr/>
                <ChildrenMenuToolbar
                  auth={this.props.auth}
                  selectedTicket={selectedTicket} 
                  onPlanningToChildren={()=> {this.props.onPlanningToChildren(selectedTicket)}}
                  onClose={()=> {
                    this.props.onSetRefresh();
                    this.props.handleClose();
                  }}
                />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.props.handleClose} variant="primary">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
    else{
      return <></>
    }  
  
  }
}

export default ChartMenuAdo;
