import * as tconst from "../common/Constants"

export function getIterationStart(sDate){
    const _date = sDate;
    _date.setHours(tconst.START_TIME);
    _date.setMinutes(0);

    return _date;
};

export function getIterationEnd(eDate){
    const _date = eDate;
    _date.setHours(tconst.END_TIME);
    _date.setMinutes(0);

    return _date;
}