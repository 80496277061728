import React from "react";
import Button from "react-bootstrap/Button";


const ChartMenuMoveExtendButtons = ({selectedTicket, onExtendIteration, enableHalfIterations, onMoveIteration}) =>{

    return (
        <>
                        <h5>Extend</h5>
                <div>
                <Button onClick={() => onExtendIteration(selectedTicket, -56, )}>
                  {" "}
                  -4{" "}
                </Button>{" "}
                &nbsp;
                <Button onClick={() => onExtendIteration(selectedTicket, -28)}>
                  {" "}
                  -2{" "}
                </Button>{" "}
                &nbsp;
                <Button onClick={() => onExtendIteration(selectedTicket, -14)}>
                  {" "}
                  -1{" "}
                </Button>{" "}
                &nbsp;
                <Button
                  disabled={!enableHalfIterations}
                  onClick={() => onExtendIteration(selectedTicket, -7)}
                  >
                  {" "}
                  -1/2{" "}
                </Button>{" "}
                &nbsp;
                <Button
                  disabled={!enableHalfIterations}
                  onClick={() => onExtendIteration(selectedTicket, 7)}
                  >
                  {" "}
                  1/2{" "}
                </Button>{" "}
                &nbsp;
                <Button onClick={() => onExtendIteration(selectedTicket, 14)}>
                  {" "}
                  1{" "}
                </Button>{" "}
                &nbsp;
                <Button onClick={() => onExtendIteration(selectedTicket, 28)}>
                  {" "}
                  2{" "}
                </Button>{" "}
                &nbsp;
                <Button onClick={() => onExtendIteration(selectedTicket, 56)}>
                  {" "}
                  4{" "}
                </Button>{" "}
                &nbsp;
              </div>

               <h5>Move</h5>
              <Button onClick={() => onMoveIteration(selectedTicket, -56, )}>
                {" "}
                -4{" "}
              </Button>{" "}
              &nbsp;
              <Button onClick={() => onMoveIteration(selectedTicket, -28)}>
                {" "}
                -2{" "}
              </Button>{" "}
              &nbsp;
              <Button onClick={() => onMoveIteration(selectedTicket, -14)}>
                {" "}
                -1{" "}
              </Button>{" "}
              &nbsp;
              <Button
                disabled={!enableHalfIterations}
                onClick={() => onMoveIteration(selectedTicket, -7)}
              >
                {" "}
                -1/2{" "}
              </Button>{" "}
              &nbsp;
              <Button
                disabled={!enableHalfIterations}
                onClick={() => onMoveIteration(selectedTicket, 7)}
              >
                {" "}
                1/2{" "}
              </Button>{" "}
              &nbsp;
              <Button onClick={() => onMoveIteration(selectedTicket, 14)}>
                {" "}
                1{" "}
              </Button>{" "}
              &nbsp;
              <Button onClick={() => onMoveIteration(selectedTicket, 28)}>
                {" "}
                2{" "}
              </Button>{" "}
              &nbsp;
              <Button onClick={() => onMoveIteration(selectedTicket, 56)}>
                {" "}
                4{" "}
              </Button>{" "}
              &nbsp;
              <Button onClick={() => onMoveIteration(selectedTicket, 84)}>
                {" "}
                6{" "}
              </Button>{" "}
              &nbsp;
       </>
    )
}

export default ChartMenuMoveExtendButtons;