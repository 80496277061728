import * as tConst from "../common/Constants";


export function createQuarterHeader (iterationHeaders) {
    let quarter = null;
    let quarterHeaders = [];
    //FIXME: Look into how to return a value from the arrow function
    // disable lint for next line - the FIXME will cover it
    // eslint-disable-next-line
    iterationHeaders.map((iteration) => {
      const start = iteration[4];
      const end = iteration[5];
      const name =
        start.getFullYear().toString().slice(-2) + "Q" + Math.ceil((start.getMonth() + 1) / 3);

      if (quarter === null) {
        quarter = {
          name,
          start,
          end,
        };
      } else if (quarter.name !== name) {
        //We are in a new quarter
        quarterHeaders.push([
          ".",
          quarter.name,
          "Period: " + tConst.formatDate(quarter.start) + " - " + tConst.formatDate(quarter.end),
          tConst.COLOR_QUARTALS,
          quarter.start,
          quarter.end,
        ]);
        quarter = { name, start, end };
      } else {
        // we are in the same quarter and should just update
        quarter.end = iteration[5];
      }
    });
    if (quarter != null) {
      quarterHeaders.push([
        ".",
        quarter.name,
        "Period: " + tConst.formatDate(quarter.start) + " - " + tConst.formatDate(quarter.end),
        tConst.COLOR_QUARTALS,
        quarter.start,
        quarter.end,
      ]);
    
    }
    return quarterHeaders;
};

export function iterationHeader(startDate, endDate) {
    let iterationHeaderData = [];
    let _startDate = new Date(2020, 0, 9, tConst.START_TIME);
    let _endDate = new Date(2020, 0, 23, tConst.END_TIME);
    let _testEndDate = new Date(2020, 0, 23, tConst.START_TIME);
    let _year = 20;
    let iterationNumber = 1;
    const now = new Date();
    while (_startDate < endDate) {
      if (_endDate < startDate) {
        //do nothing
      } else if (_startDate > endDate) {
        break;
      } else {
        iterationHeaderData.push([
          ".",
          _year.toString() + "T" + iterationNumber.toString(),
          "Period: " + tConst.formatDate(_startDate) + " - " + tConst.formatDate(_endDate),
          _startDate < now && _testEndDate > now
            ? tConst.COLOR_CURRENT_ITERATION
            : tConst.COLOR_ITERATION,
          new Date(_startDate.getTime()),
          new Date(_endDate.getTime()),
        ]);
      }
      _startDate.setDate(_startDate.getDate() + 14);
      _endDate.setDate(_endDate.getDate() + 14);
      _testEndDate.setDate(_testEndDate.getDate() + 14);

      if (_startDate.toLocaleDateString("en", { year: "2-digit" }) === _year.toString()) {
        iterationNumber++;
      } else {
        _year++;
        iterationNumber = 1;
      }
    }
    return iterationHeaderData;
  }
