import * as tConst from "../common/Constants";

export function pickColorAdo (status, planningStatus = "0. Not Started",resolution, dimColor){
  if (dimColor){
    return tConst.COLOR_DIM;
  } 
  else if (status === "Resolved" || status === "Closed"|| status === "Done") {
    if (planningStatus === tConst.PLANNING_STATUS_NA) {
      return tConst.COLOR_DONE_NA;
    } else  {
      return tConst.COLOR_DONE;
    }
  } else if (status === "Removed") {
    if (planningStatus === tConst.PLANNING_STATUS_NA) {
      return tConst.COLOR_DONE_NA;
    } else {
      return tConst.COLOR_REMOVED;
    }
  /* Må avvente hvordan vi introduserer QA i flyten for DevOps 
  } else if (status === "QA" || status === "QA WIP") {
    if (planningStatus === tConst.PLANNING_STATUS_NA) {
      return tConst.COLOR_DONE_NA;
    } else {
      return tConst.COLOR_DONE_QA;
    }
  */
  } else if ( planningStatus === "2. FuncSpec Complete" || planningStatus === "3. DevPlan in-progress") {
    return tConst.COLOR_PLANNING;
  } else if (planningStatus === "0. Not Started" || planningStatus === "1. FuncSpec in-progress") {
    return tConst.COLOR_SPECING;
  } else if (planningStatus === "4. Ready-for-coding") {
        return tConst.COLOR_READY4CODING;
  } else if (planningStatus === "9. N/A") {
    return tConst.COLOR_NA;
  } else if (planningStatus === "") {
    return tConst.COLOR_SPECING;
  }
  else {
    return tConst.COLOR_UNKNOWN;
  }
}
      