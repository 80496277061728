import React from "react";
import Select from "react-select";
import Switch from "react-switch";
import LegendDialog from "../LegendDialog";
import { Button } from "react-bootstrap";
import * as tConst from "../../common/Constants";



const ToolBarAdo = ({...props}) => {
        return (
            <>
                <table width="100%">
            <tbody>
              <tr>
                <td>
                  {" "}
                  <label>Project</label>
                </td>
                <td></td>
                <td>
                  {/*} 
                  <label bgcolor = "#f2feea">Group by</label>
                  {*/}
                </td>
                <td >
                  <label>Select View</label>
                  {/*} 
                  {*/}
                </td>
                <td  bgcolor = "#f2feea">
                  <label>Highlight Assignee</label>
                </td>
                <td>
                  {/*} 
                  <label>Highlight Initative</label>
                  {*/}
                </td>
                <td>
                  {/*} 
                  <label>Highlight Sprint</label>
                  {*/}
                </td>
                <td>{}</td>
                <td>Use Menu</td>
                <td></td>
                <td>
                  <Button variant="link" onClick={()=>{props.onLogout()}}>logout</Button>
                </td>
              </tr>
              <tr>
                <td width="17%">

                  {/* TODO: Support multiple filters */}
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options ={props.projects}
            
                    style={{ width: "40px" }}
                    name="projectId"
                    isSearchable={true}
                    value={props.projects ? props.projects.filter((filt) => {
                      return filt.value === props.selectedProject}): null}
                    onChange={props.projectOnChange}
                    />

                </td>
                <td width="3%">
                <button onClick={()=>{
                        props.onHideProject(props.selectedProject)}}>Hide</button>
                  {/*
                  <button
                    disabled={!this.getSelectedFilterViewURL()}
                    onClick={() => {
                        const selectedFilterViewUrl = this.getSelectedFilterViewURL();

                      if (selectedFilterViewUrl) {
                          console.log("Opening filter in new window " + selectedFilterViewUrl);
                        window.open(selectedFilterViewUrl, "_blank");
                      }
                    }}
                  >
                    Open
                  </button>
                    */}
                </td>
                <td width="13%">
                  {/*
                  <Select
                    options={tConst.GROUP_BY_SELECT_OPTION}
                    name="groupBy"
                    //value={this.props.groupBy}
                    defaultValue={tConst.GROUP_BY_SELECT_OPTION[this.props.groupBy - 1]}
                    onChange={this.props.groupByOnChange}
                    style={{ width: "40px" }}
                  />
                */}
                </td>
                <td width="10%">
                  {/*
                  <Select
                    options={tConst.VIEW_SELECT_OPTION}
                    name="view"
                    defaultValue={tConst.VIEW_SELECT_OPTION[this.props.view - 1]}
                    onChange={this.props.viewOnChange}
                    style={{ width: "40px" }}
                  />
                  */}
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options ={tConst.VIEW_SELECT_OPTION}
            
                    style={{ width: "40px" }}
                    name=""
                    isSearchable={true}
                    value={tConst.VIEW_SELECT_OPTION.filter((filt) => {
                      return filt.value === props.viewId}
                    )}
                    onChange={props.viewOnChange}
                    />
                </td>
                {/* Assignee */}
                <td width="10%">
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options ={props.assigneeList}
            
                    style={{ width: "40px" }}
                    name=""
                    value={props.assigneeList.filter((filt) => {
                      return filt.value === props.assigneeId}
                    )}
                    isSearchable={true}
                    onChange={props.assigneeOnChange}
                    />

                  {/*


<Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={this.props.assigneeFilterList}
                    style={{ width: "40px" }}
                    value={
                        //TODO: Change this from filter to find
                      this.props.assigneeFilterList
                        ? this.props.assigneeFilterList.filter((epic) => {
                            return epic.value === this.props.selectedAssigneeFilter;
                          })
                        : null
                    }
                    name="filterId"
                    isSearchable={true}
                    onChange={this.props.assigneeOnChange}
                  />
                        */}
                </td>
                {/* Initiative */}
                <td width="10%">
                  {/*
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={this.props.initiativeFilterList}
                    style={{ width: "40px" }}
                    value={
                        //TODO: Change this from filter to find
                      this.props.initiativeFilterList
                        ? this.props.initiativeFilterList.filter((initiative) => {
                            return initiative.value === this.props.selectedInitiativeFilter;
                          })
                        : null
                    }
                    name="filterId"
                    isSearchable={true}
                    onChange={this.props.initiativeOnChange}
                  />
                        */}
                </td>
                {/* Sprint */}
                <td width="10%">
                  {/*
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={this.props.sprintFilterList}
                    style={{ width: "40px" }}
                    value={
                      //TODO: Change this from filter to find
                      this.props.sprintFilterList
                        ? this.props.sprintFilterList.filter((sprint) => {
                            return sprint.value === this.props.selectedSprintFilter;
                          })
                        : null
                    }
                    name="filterId"
                    isSearchable={true}
                    onChange={this.props.sprintOnChange}
                  />
                */}
                </td>
                <td width="6%">
                  {
                    <button onClick={props.onRefreshClick}>Refresh</button>
                  }
                </td>
                <td width="6%">
                  <Switch
                    onChange={((event) => {
                        props.setOpenOnClickStatus(!props.openOnClickStatus);
                      }
                    )}
                      
                    checked={!props.openOnClickStatus}
                    />
                </td>
                <td></td>
                <td width="3%">
                  <LegendDialog />
                </td>
              </tr>
            </tbody>
          </table>
            </>
        );
};

export default ToolBarAdo;