import React, { useState } from 'react';
import * as tConst from '../common/Constants';
import {  Card, Col, Row, Radio, Space, Input, Dropdown, Menu, Button, Table } from 'antd';
import { todayIso } from '../utils/date';
import * as AzureService from "../api/ticketsApiAzure";


const { TextArea } = Input;

function getTriageStateFromDecision  (decision) {

    if (decision === "Ready4Triage") {
        return "Ready4Triage";
    }
    else if (decision === "Triage Yes") {
        return "Triaged";
    }
    else if (decision === "Triage No" || decision === "Cannot reproduce" || decision === "Not a Bug") {
        return "Triage No";
    }
    else if (decision === "Need More Info") {
        return "NeedMoreInfo";
    }
    else {
        debugger;
        throw new Error("Invalid value: "+ decision);
    }
}

function getTriageDecisionFromState (state, triageDecision, item) {
    if (state === "Ready4Triage") {
        return "Ready4Triage";
    }
    else if (state === "Triaged") {
        return "Triage Yes";
    }
    else if (state === "Triage No") {
        return triageDecision;
    }
    else if (state === "Ready4Triage") {
        console.debug("Ticket state unknown, using Ready4Triage as default Triage Decision");
        return "Ready4Triage";
    }
}

const TriageForm = ({ item, auth , refrech}) => {
    const [triageDecision, setTriageDecision] = useState(getTriageDecisionFromState(item.fields[tConst.AF_STATE], item.fields[tConst.AF_TRIAGE_DECISION], item));
    // todo populate this with the Triage text from the ticket
    const [triageText, setTriageText] = useState(item?.TriageData?.filter(item => item.date === todayIso())[0] ? item?.TriageData?.filter(item => item.date === todayIso())[0].text : "");
    const [triageList, setTriageList] = useState([]);
    const link = tConst.AZURE_DEVOPS_BASE_TICKET_URI + item.id;

    console.log("2024-11-02 - TriageForm: item: ", item);
    const _Status = item?.fields[tConst.AF_STATE];

    console.log("2024-11-04 - TriageForm: tConst.TRIAGE_TEXT: ", tConst.TRIAGE_TEXT);
    const __selectedTriage = tConst.TRIAGE_TEXT.filter((triageText) => {
        
        return triageText.triage === _Status;
    });
    console.log("2024-11-04 - TriageForm: __selectedTriage: ", __selectedTriage);
    
    console.log("2024-11-04 - TriageForm: _Status: ", _Status);

    //todo filter from Triage text

    const selectedTriage = tConst.TRIAGE_TEXT.filter((triageText) => {
        return triageText.triage === triageDecision;
    });


    // Find the triage text for today
    // const __TriageText = item?.TriageData?.filter(item => item.date === todayIso());
    // if (__TriageText && __TriageText.length > 0) {
    //     setTriageText(__TriageText[0].text);
    // }

    const handleSave = () => {
        const _newTriageObject = {
            date: todayIso(),
            triage: triageDecision,
            text: triageText,
        };
        debugger;
        // create objects to save:
        const _triageList = [...item.TriageData.filter(item => item.date !== _newTriageObject.date), _newTriageObject];
        const _triageDates = _triageList?.map(item => item.date).join(";");
        setTriageList([...triageList.filter(item => item.date !== _newTriageObject.date), _newTriageObject]);
        
        // Create the data to send to API
        const data = JSON.stringify([
            {
              "op": "replace",
              "path": "/fields/"+tConst.AF_TRIAGE_DATA,
              "value": JSON.stringify(_triageList)
            },
            {
                "op": "replace",
                "path": "/fields/"+tConst.AF_TRIAGE_DATES,
                "value": _triageDates
            },
            {
                "op": "replace",
                "path": "/fields/"+tConst.AF_STATE,
                "value": getTriageStateFromDecision(triageDecision)
            },
            {
                "op": "replace",
                "path": "/fields/"+tConst.AF_TRIAGE_DECISION,
                "value": triageDecision
            }
        ])  
        const comment = JSON.stringify({"text": "Triage: "+triageDecision+" - "+triageText});

        // Save the data to the Azure DevOps
        return AzureService.updateTicket(auth, item.id, data).then((response) => {
            console.log("Updated Ticket: " +JSON.stringify(response));

            return AzureService.addComment(auth, item.fields[tConst.AF_PROJECT], item.id, comment).then((response) => {
                console.log("Added Comment: " +JSON.stringify(response));
            })
    
            refrech();
        })

            
    };
        
    return (
        <>
            <div>
                <h2>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        [{item.id}]
                    </a>
                    {item.fields[tConst.AF_TITLE]}
                </h2>
            </div>
            <hr />
            <Row>
                <Col span={10}>
                    <div>
                        <div>
                            <Card
                                title="Basic"
                                size="small"
                                style={{ width: '100%', marginTop: 16, textAlign: 'left' }}
                            >
                                <div><label>Status: </label> <span style={{ fontWeight: 'bold', color: 'Darkred' }}>{item.fields[tConst.AF_STATE]}</span></div>
                                <div><label>Reporter: </label> {item.Reporter}</div>
                            </Card>

                            <Card
                                title="Description"
                                size="small"
                                style={{ width: '100%', marginTop: 16, textAlign: 'left' }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: item.fields[tConst.AF_DESCRIPTION] }} />
                            </Card>

                            <Card
                                title="Repro steps"
                                size="small"
                                style={{ width: '100%', marginTop: 16, textAlign: 'left' }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: item.fields[tConst.AF_REPRO_STEPS] }} />
                            </Card>
                            <Card
                                title="Business justification"
                                size="small"
                                style={{ width: '100%', marginTop: 16, textAlign: 'left' }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: item.fields[tConst.AF_JUSTIFICATION] }} />
                            </Card>
                        </div>
                    </div>
                </Col>
                <Col span={1}>
                    <div></div>
                </Col>
                <Col span={12}>
                    <div>
                        <h2>Triage</h2>
                        <Space>
                            <Radio.Group value={triageDecision} onChange={(e) => setTriageDecision(e.target.value)}>
                                <Radio.Button value="Ready4Triage">Ready4Triage</Radio.Button>
                                {tConst.TRIAGE_TEXT.map((triageText, index) => {
                                    return (
                                        <Radio.Button key={index} value={triageText.triage}>{triageText.triage}</Radio.Button>
                                    );
                                })}
                            </Radio.Group>
                        </Space>
                    </div>
                    <Card
                        title="Triage Text"
                        size="small"
                        style={{ width: '100%', marginTop: 16, textAlign: 'left' }}
                    >
                        <Dropdown
                            overlay={
                                <Menu>
                                    {selectedTriage && selectedTriage.length > 0 && selectedTriage[0]?.standardTexts?.map((item, index) => {
                                        return (
                                            <Menu.Item key={index + 1} onClick={() => setTriageText(item.value)}>{item.name}</Menu.Item>
                                        );
                                    })}
                                </Menu>
                            }
                        >
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                Insert default text
                            </a>
                        </Dropdown>

                        <TextArea
                            value={triageText}
                            onChange={(e) => setTriageText(e.target.value)}
                            placeholder="Write a triage text or insert a default text from the template buttons"
                            autoSize={{
                                minRows: 3,
                                maxRows: 10,
                            }}
                        />
                        <br />
                        <Button type="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </Card>
                    <Card
                        title="Old Triage Text"
                        size="small"
                        style={{ width: '100%', marginTop: 16, textAlign: 'left' }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: item.fields["Custom.OldTriageText"] }} />

                        <Table dataSource={item.TriageData.filter(item => item.date !== todayIso()).sort((a,b) => a.date > b.date ? -1 : 1)} pagination={false} rowKey="date">
                            <Table.Column title="Date" dataIndex="date" key="date" />
                            <Table.Column title="Triage Decision" dataIndex="triage" key="triage" />
                            <Table.Column title="Text" dataIndex="text" key="text" />
                        </Table>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default TriageForm;